.forma-aplikimit-panel {
  max-width: 980px;
  //   min-width: 340px;
  //   width: 80%;
  margin: 25px auto;
  //   background-color: white;

  &-tekst-kryesor {
    margin: 0;
    font-size: 32px;
    font-weight: 800;
    text-align: center;
    color: #545454;
    margin-bottom: 40px;
  }
  &-emri {
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    color: #545454;
    margin-bottom: 40px;
    margin-top: -30px;
  }
}
