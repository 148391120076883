html {
  // width: 100vw;
  background-color: #f6f6f6;
}

* {
  font-family: "Montserrat" !important;
}

html,
div,
body {
  ::-webkit-scrollbar {
    width: 12.5px;
  }

  ::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #9d9b9b;
    outline: 2px solid #fff;
    outline-offset: -2px;
    border: 0.1px solid #b7b7b7;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #7d7b7b;
  }
}
