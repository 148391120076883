.formular {
  // @media (min-width: 1200px) {
  //   max-width: 1040px;
  // }
  // @media (min-width: 992px) {
  //   max-width: 960px;
  // }
  // @media (min-width: 768px) {
  //   max-width: 720px;
  // }
  // @media (min-width: 576px) {
  //   max-width: 540px;
  // }
  max-width: 980px;
  min-width: 375px;
  width: 80%;

  margin: -71px auto 0 auto;
  @media (max-width: 650px) {
    margin: -39px auto 0 auto;
  }

  > img {
    width: 100%;
    height: auto;
    max-height: 767px;
  }
  &-forma {
    background-color: white;
    margin-top: 24px;
    &-tekst-kryesor {
      color: #545454;
      padding: 43px 43px 0px 43px;
      line-height: 1.5;
      text-align: justify;
      // font-style: bold;
    }
  }
}

.butonat {
  width: 80%;
  max-width: 980px;
  min-width: 375px;
  margin: 28px auto 0 auto;
  @media (max-width: 650px) {
    margin: 8px auto 0 auto;
  }

  display: flex;
  justify-content: flex-end;
  // margin-top: -100%;
  // margin: 0 auto;
  // position: absolute;
  // @media (min-width: 510px) {
  //   top: 2vh;
  //   left: 13vw;
  // }
  // top: 2vh;
  // left: 35vw;
  // right: 5vw;
  // margin-right: 20%;
  // right: 40%;
  // padding-top: 15px;
}
