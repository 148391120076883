// .detajet {
//   margin-bottom: 5vh;
//   padding: 16px;
//   // display: flex;
//   // flex-wrap: wrap;
//   // flex-direction: row;
//   // display: grid;
//   // grid-template-columns: repeat(auto-fit, 6fr 1fr);
//   display: grid;
//   grid-template-columns: 5fr 1fr;
//   background: #ffffff;
//   box-shadow: 0px 3px 6px #00000029;
//   border: 1px solid #c5c5c5;
//   border-radius: 5px;
//   // border: 1px dashed #545454;
//   @media (max-width: 900px) {
//     grid-template-columns: none;
//   }
//   &-kollonat {
//     flex-grow: 1;
//     display: flex;
//     flex-direction: column;
//   }
//   &-files {
//     border: 1px dashed #545454;
//     // padding: 15px;
//     display: flex;
//     flex-direction: column;
//     width: auto;
//   }
//   &-rreshtat {
//     display: grid;
//     grid-template-columns: repeat(3, minmax(150px, 1fr));
//     @media (max-width: 900px) {
//       grid-template-columns: repeat(1, minmax(150px, 1fr));
//     }
//     &-cell {
//       border: 1px dashed #545454;
//       // border-right: none;
//       // border-right: 1px dashed #545454;
//       // border-bottom: 1px dashed #545454;
//       // border-top: 1px dashed #545454;
//       padding: 15px;
//     }
//   }
// }

.detajet {
  margin-bottom: 5vh;
  padding: 15px;
  box-shadow: 0px 3px 6px #00000029;
  background-color: white;
  border: 1px solid #c5c5c5;
  border-radius: 5px;
  &-cell {
    padding: 1vh;
    // border-right: 0.1px dashed #545454;
    // border-left: 0.1px dashed #545454;
    // max-width: 30% !important;
    // @media (max-width: 640px) {
    //   max-width: 100% !important;
    // }
    // outline: 1px dashed #545454;

    padding-bottom: 2vh;
    &-item {
      margin-left: 1vw;
      margin-top: 1vh;
    }
  }
}
// .MuiGrid-align-items-xs-center {
//   align-items: center !important;
// }
