.forma-aplikimit {
  padding: 30px 43px 43px 43px;
  &-tekst-kryesor {
    margin: 0;
    font-size: 32px;
    font-weight: 800;
    text-align: center;
    color: #545454;
    // font-style: bold;
  }
  &-inputet {
    margin-top: 30px;
    &-labels {
      color: #545454;
      margin-bottom: 7px;
      font-weight: 500;
      font-size: 16px;
      @media (max-width: 500px) {
        font-size: 15px;
      }
      font-style: normal;
    }
    &-upload {
      display: flex;
      flex-direction: column;
      margin-top: 25px;
      width: 100%;

      @media (min-width: 1195px) {
        width: 47%;
      }
      &-input-me-info {
        width: 110%;

        @media (min-width: 870px) {
          width: 108%;
        }

        @media (min-width: 1030px) {
          width: 107%;
        }
      }
    }
    &-errorText {
      width: 80%;
      margin-left: 14px;
      margin-right: 14px;
      font-size: 0.75rem;
      margin-top: 3px;
      text-align: left;
      font-weight: 400;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      color: #f44336;
    }
  }
}

.MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input {
  color: #545454;
}
